/* SELECT */
#cardSelect {
  background-color: white; 
  color: black;
  border: 2px rgb(128, 135, 134) solid;
  border-radius: 0.4em;
  padding: 0.5em;
  transition: background 0.5s;
  width:9em;
  font-size: large;
  font-weight: 700;
  
}

.bn39 {
  background-image: linear-gradient(135deg, #ff0000, #000000);
  border-radius: 6px;
  /* box-sizing: border-box; */
  color: #ffffff;
  display: block;
  height: 50px;
  font-size: 1.4em;
  font-weight: 600;
  /* padding: 4px; */
  position: relative;
  text-decoration: none;
  width: 15em;
  z-index: 2;
  font-family: 'Times New Roman', Times, serif;
}

.bn39:hover {
  color: #fff;
}

.bn39 .bn39span {
  align-items: center;
  background-image: linear-gradient(135deg, #000000, #ff0000);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background 0.5s ease;
  width: 100%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.bn39:hover .bn39span {
  background: transparent;
}

#cardOption{
  background-color: white;
  color: black;
  font-size: large;
  font-weight: 700;
}

#cardSelect:hover {
  background-color: whitesmoke;
  border: 2px black solid;
  box-shadow: 0 0 1em black;
}

@media (max-width: 500px) {
  .d-flex.flex-column.align-items-center {
    text-align: center;
  }

  .d-flex.justify-content-center {
    flex-direction: column;
    align-items: center;
  }
}
*{
  font-family:  sans-serif;
  font-weight: 500;
}
p{
  font-size: 23px;
  text-align: justify;
}
#p2Text{
  font-size: 23px;
  text-align: justify;
}
#navLaptop li{
  font-size: 22px;
  text-align: center;
  list-style-type: none;
  font-weight: 650;
}
#accordionImage{
  height: 306px;
width: 225px;
}
#accordionText{
  max-width: 80%;
  font-size: 23px;
}
#card1,#card2,#card3,#card4,#card5{
  width: 200px;
  height: 272px;
}
#hold{
  color: black;
  font-weight: 400;
  font-size: large;
  border-radius: 3px;
  border: 2px solid grey;
  padding: 0 22px 0 22px;
}
#spinImage{
  width: 175px;
  height: 238px;
}

@media screen and (max-width: 1400px) {
  #accordionImage{
    height: 306px;
  width: 225px;
  }
  #accordionText{
    max-width: 80%;
    font-size: 23px;
  }
  #card1,#card2,#card3,#card4,#card5{
    width: 175px;
    height: 238px;
  }
  #spinBtn{
    width: 13em;
  }
  #p2EndImage{
    max-width: 28%;
  }
  
  
}
@media screen and (max-width: 1200px) {
  #cardSelect {
    width: 8em;
    font-size: medium;
  }
  
  #cardOption {
    font-size: large;
  }
}
@media screen and (max-width: 1000px) {
  #accordionImage{
    height: 204px;
  width: 150px;
  }
  #accordionText{
    max-width: 80%;
    font-size: 17px;
  }
  #remainingLaptopText{
    font-size: 18px;
  }
  #card1,#card2,#card3,#card4,#card5{
    width: 125px;
    height: 170px;
  }
  #spinBtn{
    width: 12em;
  }
  #p2Image{
    width: 10rem;
  }
  #p2EndImage{
    max-width: 45%;
  }
  #p2Text{
    font-size: 18px;
  }
  #cardSelect {
    width: 7em;
    font-size: small;
  }
  
  #cardOption {
    font-size: medium;
  }
  #spinImage{
    width: 125px;
    height: 170px;
  }
}

@media screen and (max-width: 770px) {
.bn39 {
font-size:1em ;
width:14em ;
  }
  #accordionImage{
  height: 170px;
  width: 125px;
  }
  #accordionText{
    max-width: 70%;
    font-size: 15px;
    line-height: 18px;
  }
  #remainingLaptopText{
    font-size: 16px;
    line-height: 18px;
  }
  #navLaptop li{
    font-size: 14.1px;
}
#card1,#card2,#card3,#card4,#card5{
  width: 99px;
  height: 115.4px;
}
#spinBtn{
  width: 10em;

}
#hold{
  width:50px;
  padding: 0;
}
#detailsInput{
  width: 11rem;
}
#p2Image{
  width: 9rem;
}
#p2Text{
  font-size: 16px;
  line-height: 18px;
}
#cardSelect {
  width: 6.3em;
  font-size: x-small;
}

#cardOption {
  font-size: small;
}
#spinImage{
  width: 91px;
  height: 120px;
}
}
@media screen and (max-width: 630px) {
  #card1,#card2,#card3,#card4,#card5{
    width: 88px;
    height: 105.4px;
  }
  #cardSelect {
    width: 6em;
    font-size: xx-small;
  }
  
  #cardOption {
    font-size: small;
  }
  #spinImage{
    width: 80px;
    height: 105px;
  }
}

@media screen and (max-width : 500px) {
  #mobileImage{
    height: 140px;
     width: 10rem;/* Decrease font size for smaller screens */
  }
  #mobileText{
    max-width: 55%;
    font-size: 18.5px;
    line-height: 40px;
  }
  #mobileRemainText{
    font-size: 20px;
  }
  #navLaptop li{
    font-size: 0.81rem;
    padding: 0.6rem;
}
#p2Image{
  width: 7rem;
}
#p2EndImage{
  max-width: 55%;
}
#spinImage{
  width: 100.5px;
  height: 120.4px;
}
#p2Text{
  font-size: 20px;
  line-height: normal;
}
#hold{
  color: black;
  font-weight: 500;
  border-radius: 3px;
  border: 2px solid grey;
}
#cardSelect {
  padding: 0.7em;
  width:11em;
  margin: -40px;
  
}

#cardOption{
  font-size: small;
  font-weight: 700;
}
}

@media screen and (max-width : 410px) {
  #mobileImage{
    height: 140px;
     width: 9.4rem;
  }
  #mobileText{
    max-width: 54%;
    font-size: 19px;
    line-height: 35px;
  }
  #mobileRemainText{
    font-size: 19px;
    line-height: 25px;
  }
  #navLaptop li{
    font-size: 0.7rem;
}
#spinImage{
  width: 99.5px;
  height:120px;
}
#p2Text{
  font-size: 19px;
  line-height: 25px;
}
#cardSelect {
  width:9em;
}
}
@media screen and (max-width : 375px) {
  #mobileImage{
    height: 120px;
     width: 8.9rem;/* Decrease font size for smaller screens */
  }
  #mobileText{
    max-width: 49%;
    font-size: 16px;
    line-height: 28px;
  }
  #mobileRemainText{
    font-size: 18px;
    line-height: 25px;
  }
  #spinImage{
    width: 85px;
    height:100px;
  }
  #p2Text{
    font-size: 18px;
    line-height: 25px;
  }
  #navLaptop li{
    font-size: 0.6rem;
}
.bn39 {
  font-size:0.8em ;
  width:14em ;
    }
  
}
@media screen and (max-width : 330px) {
  #mobileImage{
    height: 125px;
     width: 9rem;/* Decrease font size for smaller screens */
  }
  #mobileText{
    max-width: 40%;
    font-size: 14px;
    line-height: 21px;
  }
  #mobileRemainText{
    font-size: 15px;
    line-height: 23px;
  }
  #navLaptop li{
    font-size: 0.55rem;
}
#spinImage{
  width: 80.5px;
  height: 95px;
}
#p2Text{
  font-size: 15px;
  line-height: 23px;
}
#cardSelect {
  width:7em;
}
}
@media screen and (max-width : 300px) {
  #spinImage{
    width: 77.5px;
    height:90px;
  }
  #mobileImage{
    height: 130px;
     width: 8.5rem;
     max-width: 60%;
  }
}

/* Spinner Images */
@media screen and (max-width : 500px){
  #card1,#card2,#card3,#card4,#card5{
    height: 105px;
    width: 88px;
  }
  #hold{
    font-size: 16px;
    width:50px;
    padding: 0;
  }
  #spinBtn{
    width: 10rem;
  }
}
@media screen and (max-width : 475px){
  #card1,#card2,#card3,#card4,#card5{
    height: 98px;
    width: 85.5px;
  }
  #hold{
    font-size: 14px;
    width:45px;
    padding: 0;
  }
  #spinBtn{
    width: 9rem;
  }

}
@media screen and (max-width : 410px){
  #card1,#card2,#card3,#card4,#card5{
    height: 75px;
    width: 70px;
  }
  #hold{
    font-size: 13px;
    width:40px;
    padding: 0;
  }
  #spinBtn{
    width: 8rem;
  }
}
@media screen and (max-width : 340px){
  #card1,#card2,#card3,#card4,#card5{
    height: 67.8px;
    width: 60.5px;
  }
  #hold{
    font-size: 12px;
    width:38px;
    padding: 0;
  }
  #spinBtn{
    width: 6rem;
  }
}
@media screen and (max-width : 315px){
  #card1,#card2,#card3,#card4,#card5{
    height: 70.8px;
    width: 58.5px;
  }
  .bn39 {
    font-size:0.6em ;
    width:14em ;
      }
}
@media screen and (max-width : 290px){
  #card1,#card2,#card3,#card4,#card5{
    height: 70.8px;
    width: 70.5px;
  }
}
fieldset{
  border: 2px solid rgba(128, 128, 128, 0.55);
  border-radius: 5px;
  background-color: #d3d3d3da;
}

input{
  padding: 9px 25px 9px 25px;
  font-size: 16px;
  line-height: 24px;
  width: 15rem;
}
body{
  background-color:  #d3d3d3da;
}

#nav span img {
  max-width: 100%;
}

@media screen and (max-width: 550px) {
  #mobileHeading{
    font-size: 17px;
  }
}
@media screen and (max-width: 400px) {
  #mobileHeading{
    font-size: 11.9px;
  }
}
@media screen and (max-width: 300px) {
  #mobileHeading{
    font-size: 10px;
  }
}


hr{
  margin-top: -0px;
  border:1.8px solid rgba(64, 63, 66, 0.878);
}


/* Accordion switch */
.collapsible-div {
  background-color: #f0f0f0;
  /* padding: 10px; */
  max-height: 500px; /* Set a maximum height for the div */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.collapsed {
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.collapsible-div p {
  margin: 0;
}
